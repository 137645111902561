export const emitter = (contentWindow, hoopsId) => {
    let iframeContentWindow = contentWindow;
    let getEventMapper = {[hoopsId]: {}};
    let eventMapper = {};
    let getEventCounter = 0;
    let postEventCounter = 0;

    // window.addEventListener && window.addEventListener("message", (e) => {
    //     let dataObj;
    //
    //     try {
    //         dataObj = JSON.parse(e.data);
    //     } catch (e) {
    //         return;
    //     }
    //
    //     if (dataObj && dataObj.provider && "hoopsai" === dataObj.provider)
    //         dataObj.source = e.source;
    //
    //     if (dataObj.type === "get") {
    //         if (!eventMapper[dataObj.name]) {
    //             return;
    //         }
    //
    //         eventMapper[dataObj.name].forEach((func) => {
    //             if (typeof func == "function") {
    //                 func.call(dataObj, dataObj.data, (e) => {
    //                     const newEventData = {id: dataObj.id, type: "on", name: dataObj.name, client_id: dataObj.client_id, data: e, provider: "hoopsai"};
    //                     contentWindow.postMessage(JSON.stringify(newEventData), "*");
    //                 });
    //             }
    //         });
    //     } else if (dataObj.type === "on") {
    //         if (getEventMapper[dataObj.client_id] && getEventMapper[dataObj.client_id][dataObj.id]) {
    //             getEventMapper[dataObj.client_id][dataObj.id].call(dataObj, dataObj.data);
    //             delete getEventMapper[dataObj.client_id][dataObj.id];
    //         }
    //     } else if (dataObj.type === "post") {
    //         if (!eventMapper[dataObj.name]) {
    //             return;
    //         }
    //
    //         eventMapper[dataObj.name].forEach((func) => {
    //             if (typeof func == "function") {
    //                 func.call(dataObj, dataObj.data, () => {
    //                 });
    //             }
    //         });
    //     }
    // })

    return (
        {
            on: (event_name, callback) => {
                if ((eventMapper[event_name]) || (eventMapper[event_name] = [])) {
                    eventMapper[event_name].push(callback);
                }
            },
            off: (event_name, target_index) => {
                if (!eventMapper[event_name]) {
                    return !1;
                }

                const event_index = eventMapper[event_name].indexOf(target_index);

                if (event_index > -1) {
                    eventMapper[event_name].splice(event_index, 1);
                }
            },
            get: (event_name, dataObj, callback) => {
                const data = {id: getEventCounter++, type: "get", name: event_name, hoopsWidgetId: hoopsId, data: dataObj, provider: "hoopsai"};
                // getEventMapper[hoopsId][data.id] = callback;
                iframeContentWindow.postMessage(JSON.stringify(data), "*");
            },
            post: (content_window, event_name, dataObj) => {
                const data = {id: postEventCounter++, type: "post", name: event_name, data: dataObj, provider: "hoopsai"};

                if (content_window && "function" == typeof content_window.postMessage) {
                    content_window.postMessage(JSON.stringify(data), "*");
                }
            }
        }
    );
}

import {useRef, useLayoutEffect, useState, useEffect} from 'preact/hooks'
import {emitter} from '../emitter';


function Widget(props) {
    const {config, baseSrc, iframeId, title} = props;
    const [curSrcWidget, setSrcWidget] = useState('');

    const widgetIframe = useRef(null);

    useLayoutEffect(() => {
        function updateSize() {
            const widgetContainerElement = document.getElementById(config.container_id);
            widgetContainerElement.style.width = config.width ? `${config.width}px` : '100%';
            widgetContainerElement.style.height = config.height ? `${config.height}px` : '100%';

            if (!config.is_responsive) {
                widgetContainerElement.style.margin = 'auto';
            }
        }

        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, [config]);

    useEffect(() => {
        const {
            width,
            height,
            symbol,
            timezone,
            theme,
            style,
            locale,
            allow_symbol_change,
            container_id,
            minimize_side_panel,
            disable_sharing,
            disable_prefetching,
            asset_list,
            asset_class_list,
            api_key,
            layout,
            transition_direction,
            compact,
            include_content,
            include_title,
            include_infographics,
            target_num_of_columns,
            target_column_width,
            target_column_height,
            target_num_of_assets,
            is_responsive,
            refresh_rate,
        } = config;

        let srcWidget = `${baseSrc}?utm_source=${encodeURI(window.location.hostname)}&utm_medium=widget&utm_campaign=asset&utm_term=market_news`;

        if (symbol) {
            srcWidget = `${baseSrc}/${symbol}?utm_source=${encodeURI(window.location.hostname)}&utm_medium=widget&utm_campaign=asset&utm_term=${symbol}`;
        }

        if (disable_sharing) {
            srcWidget += '&disable_sharing=true'
        }

        if (disable_prefetching) {
            srcWidget += '&disable_prefetching=true'
        }

        if (theme && theme === 'dark') {
            srcWidget += '&theme=dark'
        }

        if (asset_list) {
            srcWidget += `&asset_list=${encodeURI(asset_list)}`
        }

        if (asset_class_list) {
            srcWidget += `&asset_class_list=${encodeURI(asset_class_list)}`
        }

        if (api_key) {
            srcWidget += `&api_key=${api_key}`
        }

        if (minimize_side_panel) {
            srcWidget += `&minimize_side_panel=true`
        }

        if (layout === 'horizontal') {
            srcWidget += `&layout=horizontal`
        }

        if (['left', 'right', 'top', 'bottom'].includes(transition_direction)) {
            srcWidget += `&transition_direction=${transition_direction}`
        }

        if (compact === true) {
            srcWidget += `&compact=${compact}`
        }

        if (include_content === true) {
            srcWidget += `&include_content=${include_content}`
        }

        if (include_infographics === true) {
            srcWidget += `&include_infographics=${include_infographics}`
        }

        if (include_title === true) {
            srcWidget += `&include_title=${include_title}`
        }

        if (target_num_of_columns) {
            srcWidget += `&target_num_of_columns=${target_num_of_columns}`
        }

        if (target_column_width) {
            srcWidget += `&target_column_width=${target_column_width}`
        }

        if (target_column_height) {
            srcWidget += `&target_column_height=${target_column_height}`
        }

        if (target_num_of_assets) {
            srcWidget += `&target_num_of_assets=${target_num_of_assets}`
        }

        if (is_responsive === true) {
            srcWidget += `&is_responsive=${is_responsive}`
        }

        if (refresh_rate) {
            srcWidget += `&refresh_rate=${refresh_rate}`
        }

        setSrcWidget(srcWidget);
    }, [config])

    function handleIframe() {
        const iframeItem = widgetIframe.current;
        const curEmitter = emitter(iframeItem.contentWindow, config.container_id)
        curEmitter.get("widgetReady", {})

        const widgetContainerElement = document.getElementById(config.container_id);
        widgetContainerElement.style.width = config.width ? `${config.width}px` : '100%';
        widgetContainerElement.style.height = config.height ? `${config.height}px` : '100%';

        if (!config.is_responsive) {
            widgetContainerElement.style.margin = 'auto';
        }
    }

    return (
        <div id={`${config.container_id}-wrapper`}
             style={{
                 position: 'relative',
                 boxSizing: 'content-box',
                 width: '100%',
                 height: '100%',
                 margin: '0 auto !important',
                 padding: '0 !important',
                 fontFamily: 'Arial,sans-serif'
             }}
        >
            <div style={{
                width: '100%',
                height: '100%',
                background: 'transparent',
                padding: '0 !important'
            }}
            >
                <iframe id={iframeId}
                        src={curSrcWidget}
                        title={title}
                        ref={widgetIframe}
                        onLoad={handleIframe}
                        style={{
                            width: '100%',
                            height: '100%',
                            margin: '0 !important',
                            padding: '0 !important',
                            backgroundColor: 'transparent'
                        }}
                        scrolling="no"
                        allowFullScreen=""
                        frameBorder="0"
                />
            </div>
        </div>
    );
}

export default Widget;

import React from 'react';
import ReactDOM from 'react-dom';

import Widget from "./containers/Widget";
import reportWebVitals from "./reportWebVitals";

if (!window.hoopsai) {
    function getId() {
        return "hoopsai_" + ((1048576 * (1 + Math.random())) | 0).toString(16).substring(1);
    }

    const hoopsai = {

        widgets: {
            asset: (config, onPerfEntry) => {
                config.container_id = config.container_id || getId();
                ReactDOM.render(
                    <React.StrictMode>
                        <Widget config={config}
                                baseSrc={'https://widgets.hoopsai.com/embeddedwidget'}
                                iframeId={'hoops-asset-widget'}
                                title={'hoopsAI Asset Widget'}/>
                    </React.StrictMode>,
                    document.getElementById(config.container_id)
                );

                reportWebVitals(onPerfEntry);
            },
            technical: (config, onPerfEntry) => {
                config.container_id = config.container_id || getId();
                ReactDOM.render(
                    <React.StrictMode>
                        <Widget config={config}
                                baseSrc={'https://widgets.hoopsai.com/embeddedwidget/technical'}
                                iframeId={'hoops-technical-widget'}
                                title={'hoopsAI Technical Widget'}/>
                    </React.StrictMode>,
                    document.getElementById(config.container_id)
                );

                reportWebVitals(onPerfEntry);
            },
            marketNews: (config, onPerfEntry) => {
                config.container_id = config.container_id || getId();
                const baseSrc = config.story_type ? `https://widgets.hoopsai.com/embeddedwidget/storytype/${config.story_type}` : 'https://widgets.hoopsai.com/embeddedwidget/research';

                ReactDOM.render(
                    <React.StrictMode>
                        <Widget config={config}
                                baseSrc={baseSrc}
                                iframeId={'hoops-market-news-widget'}
                                title={'hoopsAI Market News Widget'}/>
                    </React.StrictMode>,
                    document.getElementById(config.container_id)
                );

                reportWebVitals(onPerfEntry);
            },
            watchlist: (config, onPerfEntry) => {
                config.container_id = config.container_id || getId();
                config.symbol = config.watchlist;

                ReactDOM.render(
                    <React.StrictMode>
                        <Widget config={config}
                                baseSrc={'https://widgets.hoopsai.com/embeddedwidget/watchlist'}
                                iframeId={'hoops-watchlist-widget'}
                                title={'hoopsAI Watchlist Widget'}/>
                    </React.StrictMode>,
                    document.getElementById(config.container_id)
                );

                reportWebVitals(onPerfEntry);
            },
        }
    };

    if (process.env.NODE_ENV === 'development') {
        // hoopsai.widgets.asset({
        //     is_responsive: false,
        //     width: 800,
        //     height: 610,
        //     symbol: "msft",
        //     timezone: "Etc/UTC",
        //     theme: "light",
        //     style: "1",
        //     locale: "en",
        //     allow_symbol_change: true,
        //     disable_sharing: false,
        //     disable_prefetching: false,
        //     minimize_side_panel: false,
        //     asset_list: '',
        //     container_id: "root"
        // }, console.log);
        //
        // hoopsai.widgets.technical({
        //     is_responsive: false,
        //     width: 800,
        //     height: 610,
        //     symbol: "msft",
        //     timezone: "Etc/UTC",
        //     theme: "light",
        //     style: "1",
        //     locale: "en",
        //     allow_symbol_change: true,
        //     disable_sharing: false,
        //     disable_prefetching: false,
        //     minimize_side_panel: false,
        //     asset_list: '',
        //     container_id: "root"
        // }, console.log);
        //
        // hoopsai.widgets.marketNews({
        //     is_responsive: false,
        //     width: 800,
        //     height: 610,
        //     timezone: "Etc/UTC",
        //     theme: "light",
        //     style: "1",
        //     locale: "en",
        //     allow_symbol_change: true,
        //     disable_sharing: false,
        //     disable_prefetching: false,
        //     minimize_side_panel: false,
        //     asset_list: '',
        //     asset_class_list: '',
        //     layout: 'vertical',
        //     transition_direction: 'left',
        //     container_id: "root"
        // }, console.log);
        //
        // hoopsai.widgets.watchlist({
        //     is_responsive: false,
        //     width: 800,
        //     height: 610,
        //     timezone: "Etc/UTC",
        //     theme: "light",
        //     style: "1",
        //     locale: "en",
        //     allow_symbol_change: true,
        //     disable_sharing: false,
        //     disable_prefetching: false,
        //     minimize_side_panel: false,
        //     watchlist: 'aapl,tsla,fb',
        //     container_id: "root"
        // }, console.log);
        //
        hoopsai.widgets.marketNews({
            is_responsive: true,
            // width: 800,
            height: 610,
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "en",
            allow_symbol_change: true,
            disable_sharing: false,
            disable_prefetching: false,
            minimize_side_panel: false,
            asset_list: '',
            asset_class_list: '',
            story_type: 'top_gainers',
            layout: 'horizontal',
            include_content: true,
            include_infographics: true,
            container_id: "root",
            target_num_of_columns: 3,
            target_column_width: 350,
        }, console.log);
    }

    window.hoopsai = hoopsai;
}

export default window.hoopsai;